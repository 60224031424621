import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled, { keyframes } from 'styled-components'
import { BreadCrumb, Section, Tags, ComingSoon } from '../components/Section'
import CaseStudies from "../components/CaseStudies"
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton"
import Benifits from '../components/Benifits'
import OurSolution from "../sections/service/ourSolution"
import BrandsWeTrust from "../sections/service/brandWeTrusted"
import CustomerReiview from "../sections/service/customerReview"
import Faq from "../sections/service/faq"
import LetastArticles from "../components/LetastArticles"
import GetStarted from '../components/GetStarted'
import BreakpointDown from '../components/Media/BreakpointDown'
import BreakpointUp from "../components/Media/BreakpointUp"
import { StaticImage } from "gatsby-plugin-image"
import PlusIcon from '../components/Icons/PlusIcon'
import DotteLineArrowIcon from '../components/Icons/DotteLineArrowIcon';
import SecondaryLinkButton from "../components/Button/SecondaryLinkButton"


const MonitoringSolutions = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  min-height:800px;
  padding-bottom: 44px;
  position:relative;
  flex-direction:wrap;
  ${BreakpointDown.xl` 
    padding-bottom:50px;
    min-height:auto;
  `}
  ${BreakpointDown.lg`
      padding-bottom:20px;
  `}
   ${BreakpointDown.md`
      padding-bottom:20px;
  `}
`
const MonitoringSolutionsItem = styled.div` 
  flex:0 0 50%;
  width:50%;
  padding-left:15px;
  padding-right:15px;
  position:relative;
  &:last-child{
    display:none;
   ${BreakpointUp.xl`
     padding-left:0;
     display:block;
   `}
  }
&:first-child{
  padding-right:75px;
  padding-top:210px;
  ${BreakpointDown.xl` 
    padding-top:120px;
    min-height:auto;
  `}
  ${BreakpointDown.lg`
      padding-top:120px;
  `}
   ${BreakpointDown.md`
      padding-top:90px;
  `}
  ${BreakpointDown.md`
    padding-right:15px;
  `}
}
${BreakpointDown.xl` 
  flex:0 0 100%;
  width:100%;
`}
`
const ServiceTitle = styled.h1`
  /* max-width:445px; */
  margin-top:20px;
  margin-bottom:20px;
  line-height:48px;
 ${BreakpointDown.lg`
   line-height:inherit;
 `}
`
const MonitoringSolutionsItemContent = styled.p`
font-size:16px;
`
const MonitoringSolutionsBottomWrapper = styled.div`
 position:relative;
`

const MonitoringSolutionsBottom = styled.div`
  display:flex;
  flex:0 0 100%;
  left:0;
  right:0;
  position:absolute;
  bottom:-60px;
  font-size:20px;
  font-family: 'Comic Neue', cursive;
  z-index:2;
  height:120px;
  flex-wrap:wrap;
  margin-right: 60px;
  margin-left: 60px;
  @media (min-width:2400px){
    margin-left:200px;
    margin-right:200px; 
  }
  @media (max-width: 1024px) {
    margin-right: 0;
    margin-left: 0;
  }

  @media(max-width:1280px){
    bottom:0px; 
  }
  ${BreakpointDown.xl`
    position:static;
  `}
  ${BreakpointDown.md`
    height:auto;
  `}
`
const MonitoringSolutionsBottomLeft = styled.div`
flex: 0 0 50%;
  background:#529A00;
  padding:10px 45px;
  color:#FFFFFF;
  position:relative;
  ${BreakpointDown.lg`
    padding:10px 15px;
  `}
   ${BreakpointDown.md`
    flex: 0 0 100%;
  `}
&:after{
  position:absolute;
  right:-50px;
  top:50%;
  transform:translateY(-50%);
  content:'';
  width:80px;
  height: 2px;
  margin-top: 2px;
  background-image: linear-gradient(90deg, #fff, #fff 75%, transparent 0%, transparent 100%); 
  background-size: 20px 1px;
  border: none;
  ${BreakpointDown.md`
    display:none
  `}
}
`
const InnerText = styled.div`
  display:flex;
  align-items:center;
  height: 100%;
  justify-content:flex-end;
  ${BreakpointDown.md`
      line-height: 27px;
  `}
`
const MonitoringSolutionsBottomRight = styled.div`
  flex:0 0 50%;
  padding:15px 45px;
  background:#000000;
  @media(max-width:1280px){
    flex:0 0 50%;
  }
  ${BreakpointDown.lg`
    padding:15px 15px;
  `}
  ${BreakpointDown.md`
    flex: 0 0 100%;
  `}
`
const BtnWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content:center;
.btn{
  ${BreakpointUp.md`
   margin-left:40px; 
  `}
  padding:0;
  .text{
    color:#fff;
    letter-spacing: 3.2px;
    font-size:16px;
    ${BreakpointDown.lg`
      letter-spacing: 1.1px;
    `}
    &:before{
      background: #529A00;
    }
    &:after{
      background: #fff;
    }
  }
}
`
const ImgWrapper = styled.div`
 max-width:100%;
  height:100%;
 display:block;
 position: absolute;
right:15px;
 .gatsby-image-wrapper{ width:100%; height:100%;}
`
// our services
const OurSolutionTitle = styled.div`
 
`
const HeadingWrapper = styled.div`
 display:flex;
 margin-left:-15px;
 margin-right:-15px;
 flex-wrap:wrap;
 justify-content:space-between;
`
const HeadingItem = styled.div`
  padding-left:15px;
  padding-right:15px;
`
const OurSolutionList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:40px;
 border-top:1px solid #CCCCCC;
 padding-top:40px;
 ${BreakpointDown.xl`
  padding-top:20px;
  padding-bottom:0;
  margin-top: 20px;
  text-align:center;
 `}
`
const OurSolutionListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
flex:0 0 100%;
text
 `}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
 display:none;
 `}
}
`
const OurSolutionListRight = styled.div`
flex:1;
padding-left:90px;
padding-top:20px;
${BreakpointDown.xl`
padding-left:0;
 `}
`
const OurSolutionListRightTitle = styled.div`
`
const OurSolutionListRightContent = styled.div`

`
// faq




const FadeIn = keyframes`
 from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const SectionBg = styled(Section)`
  position:relative;
  box-shadow: 0px 20px 85px #00000033;
  z-index: 2;
&:before, &:after{
  width:50%;
  ${BreakpointDown.xl`
    display:none;
    `
  }
}
.container{z-index:1}
`

const FaqTitle = styled.div`
 text-transform:uppercase;
 margin-bottom:0;
`

const FaqRow = styled.div`
 display:flex;
 flex-wrap:wrap;
 justify-content:space-around;
 align-items:center;
`
const FaqColumn = styled.div`
  flex: 0 0 50%;
  width:50%;
  position:relative;
  padding-top:150px;
  padding-bottom:150px;
  
  ${BreakpointDown.xl`
      flex: 0 0 100%;
      width:100%;
  `}
  &:first-child{
    border-right: 1px solid #00000033;
    box-shadow: 1px 0px 2px 0px #00000033;
    background:#fff;
    ${BreakpointDown.xl`
      padding-top:30px;
      padding-bottom:0px;
      border-right:0;
      box-shadow:none;
    `
  }
  }
  &:only-child{
    flex: 0 0 100%;
      width:100%;
      border-right:0;
      box-shadow:none
  }
  &:last-child{
    padding: 105px;
    ${BreakpointDown.xl`
        padding: 30px 0px;
      `
  }
   &:before{
     content:"?";
     font-size:800px;
     font-weight:900;
      position:absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
     font-family: "Nunito Sans", sans-serif;
     text-transform:uppercase;
     letter-spacing: -8px;
     color: #000000;
     opacity: 0.05;
     display:none;
     ${BreakpointDown.xl`
       display:none;
    `
  }
   }
  }
`
const TabList = styled.div` 
`
const TabListDesktop = styled(TabList)`
display:none;
${BreakpointUp.xl`
   display:inherit
`
  }
`
const TabListMobile = styled(TabList)`
display:none;
${BreakpointDown.xl`
   display:inherit
   `
  }
`
const TabListItem = styled.div`
padding-top:20px;
padding-bottom:20px;
display:flex;
justify-content:space-between;
align-items:center;
padding-left:20px;
padding-right:20px;
margin-bottom:0;
position: relative;
font-family: "Nunito Sans", sans-serif;
line-height: 24px;
font-weight: bold;
cursor: pointer;
${BreakpointDown.xl`
  flex-direction:column;
  background:#fff;
  border-bottom: 1px solid #00000033;
  padding-left:15px;
padding-right:15px;
 &:nth-of-type(){
  border-top: 1px solid #00000033;
 }
 `}
 ${BreakpointDown.md`
  padding-right:35px;
 `}

svg{
  transition: all 0.1s linear;
  position:absolute;
  right:0;
  ${BreakpointDown.md`
    right:-15px;
 `}
}
&:before{
    content:'';
    background:#F8F8F8;
    position:absolute;
    left:0;
    right:-40px;
    top:0;
    bottom:0;
    display:none;
    ${BreakpointDown.xl`
     right:0;
    `}
  }
&+&{
  border-top: 1px solid #00000033;
}
&.active{
  >div{
    color:#000;
  }
  svg{
    transform: rotate(45deg);
    path{
      fill:#000;
    }
  }
  .icon{
     display:block;
     ${BreakpointDown.xl`
    display:none;
    `}
  
  }
&:before{
  display:block;
}
}
`
const Text = styled.div`
  position:relative;
  z-index:1;
  display:flex;
  justify-content:space-between;
  align-items:center;
  width: 100%;
  color:#666666;
`
const Icon = styled.div`
  position:absolute;
  right:-30px;
  display:none;
  margin-top: 8px;
  transform: rotate(224deg);

 svg{
   margin-left:15px;
   path{
     fill:#000;
   }
 }
`
const TabContnet = styled.div`
  position:relative;
  z-index:1;
 
`
const TabContentItem = styled.div`
  animation-duration: 1s;
  animation-name: ${FadeIn};
  animation-fill-mode: both;
  transition: opacity 0.15s linear;
  &.tab-item{
    ${BreakpointUp.xl`
    display:none;
    `
  }
    &.active{
      ${BreakpointUp.xl`
        display:inherit;
        `
  }
    .tab-content-inner{
      padding-top:20px;
      height:auto;
      overflow:auto;
    }
    }
  }
`
const TabContentinner = styled.div`
   ${BreakpointDown.xl`
      height:0;
      overflow:hidden;
      transition: height 0.15s linear; 
    `
  }
`
//Letast BLogs


const Card = styled.div`
  padding: 70px 50px ;
  background:#fff;
  box-shadow: 0px 20px 45px rgb(0, 0, 0, 0.20);
  /* @media (min-width:2200px){
    padding: 20px 15px;
  } */
  @media(max-width:1440px){
    padding: 20px 14px; 
  }
  ${BreakpointDown.lg`
   text-align:center;
 `}
  .btn{
    margin-top:15px;
  }
`
const CardTitle = styled.div`
 margin-bottom:6px;
 font-weight:bold;
`
const CardImg = styled.div`
  position: relative;
  max-width:100%;
  width:100%;
  margin-top:26px;
  .gatsby-image-wrapper{
    width:100%;
  }
`
const Date = styled.div`
  color:#666666;
  font-size:10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  @media (min-width:2200px){
    font-size:14px;
  }
`
const SectionHeader = styled.div`
 text-align:center;
 .h1{ margin-bottom:0px;}
 ${BreakpointDown.md`
 p{
   margin-bottom:0;
 } 
 `}
`
const Title = styled.h2`

`

const Blogs = styled.div`
 display: flex;
 margin-inline:-15px;
 flex-wrap:wrap;
 margin-top:50px;
`
const BlogItems = styled.div`
 flex:0 0 auto;
 width:33.3333%;
 padding-inline:15px;
 @media(max-width:767px){
   width:100%;
  & + &{
    margin-top:20px;
  }
 }
`

function ServicePage() {
  const [activeTab, setactiveTab] = useState(0);
  const HandleTabs = (key) => {
    setactiveTab(key);
  };
  return (
    <Layout pageName="service">
      <SEO title="Fleet Management Solutions and Vehicle GPS Tracking Systems 
" description="Improve your fleet management with accurately tracking mileage, location and travel time with GPS tracking solutions by WYN Technologies. Get Started today." />
      <Section bgColor="#F8F8F8" pt="0" pb="0">
        <div className="container">
          <MonitoringSolutions>
            <MonitoringSolutionsItem>
              <BreadCrumb>
                <Link to="/">Home</Link><Link to="/">Services</Link><Link to="/#">Vehicle GPS Tracking System Installation</Link>
              </BreadCrumb>
              <ServiceTitle>Vehicle GPS Tracking System Installation</ServiceTitle>
              <MonitoringSolutionsItemContent>
              Want to be able to keep a better account of things like fuel cost, vehicle mileage, and current location specifics for every individual driver in your fleet, along with the entire fleet itself? With a proper vehicle GPS tracking system in place, you’ll be much better equipped for planning schedules and tracking respective ETAs. <strong>WYN</strong> can help you take full advantage of GPS fleet tracking technology by installing the right vehicle tracking solutions for your business. Feel free to ask us about how you can better protect your assets and personnel through the implementation of automobile GPS technology and its related applications.
              </MonitoringSolutionsItemContent>
            </MonitoringSolutionsItem>
            <MonitoringSolutionsItem>
              <ImgWrapper>
                <StaticImage
                  src="../images/vehicle-gps.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Letast Article"
                  placeholder="blurred"
                />
              </ImgWrapper>
            </MonitoringSolutionsItem>
          </MonitoringSolutions>
        </div>
      </Section>
      <MonitoringSolutionsBottomWrapper>
        <div className="container">
          <MonitoringSolutionsBottom>
            <MonitoringSolutionsBottomLeft>
              <InnerText>We ensure that you get the right technologies and best products</InnerText>
            </MonitoringSolutionsBottomLeft>
            <MonitoringSolutionsBottomRight>
              <BtnWrapper>
                <PrimaryLinkButton text="Solution You’re Looking!" />
              </BtnWrapper>
            </MonitoringSolutionsBottomRight>
          </MonitoringSolutionsBottom>
        </div>
      </MonitoringSolutionsBottomWrapper>
      <Benifits />
      <Section bgColor="#F8F8F8" pt="90px" pb="90px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <HeadingWrapper>
            <HeadingItem>
              <OurSolutionTitle className="h1">What We Provide with Our GPS Vehicle Tracking System Services</OurSolutionTitle>
              {/* <p>Lorem Ipsum is simply dummy text of the printing and type setting industry.</p> */}
            </HeadingItem>
          </HeadingWrapper>

          <OurSolutionList>
            <OurSolutionListLeft>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </OurSolutionListLeft>
            <OurSolutionListRight>
              <OurSolutionListRightTitle className="h3">Free, No-Obligation Product Demos </OurSolutionListRightTitle>
              <OurSolutionListRightContent><p>Perhaps you’ve observed vehicle tracking systems being used by another business or service provider, but you’ve not had the chance to try them out firsthand and experience how they work for yourself. Not a problem – <strong>WYN</strong> would be happy to give you that opportunity! If you’re new to GPS tracking systems, or would just like to learn more about all the potential applications and benefits, we can give you a complete product demo. We’ll show you what a system like this can deliver, and we can help you understand how your business can take full advantage. We’re here to help!</p>
              </OurSolutionListRightContent>
            </OurSolutionListRight>
          </OurSolutionList>
          <OurSolutionList>
            <OurSolutionListLeft>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </OurSolutionListLeft>
            <OurSolutionListRight>
              <OurSolutionListRightTitle className="h3">Sales, Installation, & Product Orientation</OurSolutionListRightTitle>
              <OurSolutionListRightContent><p>Are you in the transportation, distribution, or warehousing business? <strong>WYN</strong> can help by offering you the right GPS tracking products for your needs. Plus, we’re much more than simply product vendors; we’re really about providing complete solutions! When you partner with us, you can rest a little easier knowing that we can help take care of everything from installation to implementation, start to finish. And we won’t leave you guessing about how to use your new technology, either. We’ll be glad to walk you and your team through comprehensive product orientation and training. We’re happy to answer your questions along the way, too!</p></OurSolutionListRightContent>
            </OurSolutionListRight>
          </OurSolutionList>
          <OurSolutionList>
            <OurSolutionListLeft>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </OurSolutionListLeft>
            <OurSolutionListRight>
              <OurSolutionListRightTitle className="h3">Ongoing Support Whenever You May Need It</OurSolutionListRightTitle>
              <OurSolutionListRightContent><p>Ever had a commercial technology provider leave you feeling all alone and on your own once your installation was complete? Hey, we’ve been there too, and we know how that feels on the receiving end. Thankfully, you won’t have to experience that unpleasant feeling whenever you partner with us at <strong>WYN Technologies</strong>! Our goal is to be here for you whenever you may need us. We also offer some convenient “service after the sale” contracts, if you’d prefer for us to keep your equipment maintained for you. Just give us a call anytime you have a question or issue!</p></OurSolutionListRightContent>
            </OurSolutionListRight>
          </OurSolutionList>
          
        </div>
      </Section>
      <BrandsWeTrust />
      <CustomerReiview />
      <CaseStudies />
      <GetStarted />
      <SectionBg bgBefore="#fff" bgAfter="#fff">
        <div className="container">
          <FaqRow>
            <FaqColumn>
              <FaqTitle className="h1">faq</FaqTitle>

              <TabListDesktop>
                {[
                  {
                    title: 'Why choose to install a GPS system for fleet management?',
                  },
                  {
                    title: 'What’s involved in the GPS system installation process, exactly?',
                  },
                  {
                    title: 'Where would a GPS tracking device be installed on a car, truck, or other fleet vehicle?',
                  },
                  {
                    title: 'What’s the difference between a “live” and a passive GPS tracking system?',
                  },
                  {
                    title: 'How long do GPS tracking devices usually last?',
                  },
                  {
                    title: 'What can I expect in terms of cost for a GPS vehicle tracking system?',

                  }
                ].map((item, index) => (<TabListItem key={index} onClick={() => HandleTabs(index)} className={`${activeTab === index ? "active" : ""}`}><Text>{item.title} <PlusIcon /> <Icon className="icon"><DotteLineArrowIcon /></Icon></Text>
                </TabListItem>
                ))}
              </TabListDesktop>

            </FaqColumn>
            <FaqColumn>
              <TabContnet>
                {[
                  {
                    title: 'Why choose to install a GPS system for fleet management?',
                    content: `<p>Most every modern vehicle fleet has some sort of vehicle tracking system in place, and those that don’t will likely be forced to do so, in order to comply with existing FMCSA safety regulations for tracking hours of service (HOS) for your drivers.</p>

                    <p>Beyond meeting the minimum legal requirements, many fleet managers have also enjoyed the many other benefits offered by GPS fleet tracking systems, including cost reduction, improved driver safety, and greater operational efficiency. Given these results, there’s really no downside when it comes to choosing to install a GPS tracking system!</p>
                    `
                  },
                  {
                    title: 'What’s involved in the GPS system installation process, exactly?',
                    content: `Installation is really one of the most important components of a successful deployment, especially as it concerns larger fleets of vehicles. Sadly, many dealers will tend to gloss over this step during their sales process with you. A good partner will take the time to discuss the GPS system installation plan with you in detail, and should be able to demonstrate their levels of both expertise and experience with similar projects for clients. If all they say is that “it shouldn’t be an issue,” then you might want to keep looking for another provider! You should also make a point of asking how service work is done, and what happens should you decide to transfer a device to a new vehicle.
                    `
                  },
                  {
                    title: 'Where would a GPS tracking device be installed on a car, truck, or other fleet vehicle?',
                    content: 'You can install a GPS tracking device practically anywhere on a car or other fleet vehicle – in the front or rear bumper, inside a wheel well, under a seat, or in the glove compartment. But for fleet tracking purposes, most GPS trackers are installed on the dashboard through an onboard diagnostics (OBD) port. Most OBD devices today can be paired to your smart device, allowing you to monitor your fleet from any location. Drivers can pair with these devices too, in order to generate reports or simply learn more about their driving habits, routes, and any overages.'
                  },
                  {
                    title: 'What’s the difference between a “live” and a passive GPS tracking system?',
                    content: `The data being collected is generally the same for both types of systems. Live vehicle tracking transmits GPS data to active cellular networks which route it to directly to a website where you view the data in essentially real time. This is known as “live tracking” because it becomes visible almost immediately after being logged. Passive GPS trackers, on the other hand, store GPS information on the tracker itself until the vehicle returns to a central location or office. Once the vehicle is within range of a wireless base station, the GPS tracker will transfer any stored data. Passive technology systems like this have become somewhat outdated however, and are no longer readily available for purchase or installation.`
                  },
                  {
                    title: 'How long do GPS tracking devices usually last?',
                    content: 'This really depends upon the particular product you choose, along with the regular usage conditions it must endure while in service. Wired devices are often warrantied by the manufacturer for 5 years or more, but the fact of the matter is that a locator’s service life can extend well beyond that, as long as it’s installed correctly and properly maintained. The same goes for wireless trackers, as well. Got some more specific questions about GPS tracking devices and their expected service life? Just reach out to one of the pros at <strong>WYN Technologies</srong> today!'
                  },
                  {
                    title: 'What can I expect in terms of cost for a GPS vehicle tracking system?',
                    content: 'As is the case for many things – and especially as it concerns modern technology – the old adage of “you get what you pay for” holds true for GPS tracking systems, too. While you may be tempted to consider a particular device that carries a lower price tag, what you get in return will often include fewer features, services, and options. Real ROI is realized long after the initial “honeymoon period” is over. If you’re looking for a real win-win, then choose a technology partner who is committed to be there for you long after the sale – a partner like <strong>WYN</strong>!'
                  }
                ].map((item, index) => {
                  return (
                    <TabContentItem key={index} className={activeTab === index ? 'tab-item active' : 'tab-item'} >
                      <TabListMobile> <TabListItem key={index} onClick={() => HandleTabs(index)} className={`${activeTab === index ? "active" : ""}`}><Text>{item.title}<PlusIcon /> <Icon className="icon"><DotteLineArrowIcon /></Icon></Text>
                      </TabListItem></TabListMobile>
                      <TabContentinner className="tab-content-inner">
                        <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                      </TabContentinner>
                    </TabContentItem>
                  )
                })}
              </TabContnet>
            </FaqColumn>
          </FaqRow>
        </div>
      </SectionBg>
      <Section pt="80px" mpt="40px" bgColor="#F8F8F8" pb="50px" mpb="40px" xxpt="100px" xxpb="100px">
        <div className="container">
          <SectionHeader>
            <Title className="h1">Latest Articles & Blogs</Title>
          </SectionHeader>
          <ComingSoon textAlign="center">Coming Soon...</ComingSoon>
          {/* <Blogs>
            <BlogItems>
              <Card>
                <Date>24 June, 2020</Date>
                <CardTitle className="h6">We were recently featured on the local WXII news channel with regard to Thermal Temperature Monitoring.</CardTitle>
                <Tags to="/">Surveillance System</Tags> <Tags to="/">Thermal Monitoring</Tags>
                <CardImg>
                  <StaticImage
                    src="../../images/article-1.jpg"
                    quality={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Letast Article"
                    placeholder="blurred"
                  />
                </CardImg>
                <Link to="/"><SecondaryLinkButton text="Learn More" /></Link>
              </Card>
            </BlogItems>
            <BlogItems>
              <Card>
                <Date>24 June, 2020</Date>
                <CardTitle className="h6">We were recently featured on the local WXII news channel with regard to Thermal Temperature Monitoring.</CardTitle>
                <Tags to="/">Surveillance System</Tags> <Tags to="/">Thermal Monitoring</Tags>
                <CardImg>
                  <StaticImage
                    src="../../images/article-1.jpg"
                    quality={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Letast Article"
                    placeholder="blurred"
                  />
                </CardImg>
                <Link to="/"><SecondaryLinkButton text="Learn More" /></Link>
              </Card>
            </BlogItems>
            <BlogItems>
              <Card>
                <Date>24 June, 2020</Date>
                <CardTitle className="h6">We were recently featured on the local WXII news channel with regard to Thermal Temperature Monitoring.</CardTitle>
                <Tags to="/">Surveillance System</Tags> <Tags to="/">Thermal Monitoring</Tags>
                <CardImg>
                  <StaticImage
                    src="../../images/article-1.jpg"
                    quality={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Letast Article"
                    placeholder="blurred"
                  />
                </CardImg>
                <Link to="/"><SecondaryLinkButton text="Learn More" /></Link>
              </Card>
            </BlogItems>
          </Blogs> */}
        </div>
      </Section>
    </Layout>
  )
}

export default ServicePage
